<template>
  <div class="layout">
    <Header :hasSearch="false" :hasShadow="true" />

    <div class="container mt-60 mb-20 pb-20">
      <div class="vlog-header">
        <div class="d-flex align-items-center w-100">
          <div>
            <el-avatar
              size="large"
              class="vlog-avatar"
              :src="
                detail.logo
                  ? viewImg(detail.logo)
                  : require('@/assets/images/unknow.png')
              "
            ></el-avatar>
          </div>
          <div>
            <h4>{{ detail.name }}</h4>
          </div>
        </div>
        <div v-if="detail"></div>
      </div>

      <el-card
        class="mt-20"
        shadow="hover"
        :body-style="{ padding: '20px' }"
        v-loading="loading"
      >
        <div slot="header">
          <ul class="vlog-menu">
            <li :class="{ active: !tab }" @click="switchTab(0)">
              视频({{ params.total }})
            </li>
            <li :class="{ active: tab == 1 }" @click="switchTab(1)">简介</li>
          </ul>
        </div>
        <div
          v-if="tab == 1"
          v-html="$utils.html2content(detail.description)"
        ></div>
        <div class="list" style="margin-top: -20px" v-if="tab === 0">
          <div
            class="list-item"
            v-for="(item, i) in lists"
            :key="'item' + item.id"
          >
            <div class="list-item-body">
              <router-link
                :to="{ path: '/video/detail/' + item.id }"
                target="_blank"
              >
                <img
                  v-if="item.thumb"
                  :src="viewThumb(item.thumbData.thumb, 'medium')"
                  class="thumb"
                />
                <h3>
                  {{ item.title }}
                </h3>
              </router-link>
              <div
                class="
                  list-item-desc
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <div class="d-flex align-items-center">
                  <NickName v-model="item.user"></NickName>
                </div>
                <div>{{ $utils.contrastTime(item.create_time) }}</div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center mt-10"
              >
                <div
                  class="pointer d-flex align-items-center"
                  @click="fav(item, i, $event)"
                >
                  <icon type="like" size="14"></icon>
                  <span class="text-gray-6 ml-10 mr-10 small">{{
                    item.fav
                  }}</span>
                </div>
                <div
                  class="pointer d-flex align-items-center"
                  @click="unfav(item, i, $event)"
                >
                  <icon type="unlike" size="14"></icon>
                  <span class="text-gray-6 ml-10 small">{{ item.unfav }}</span>
                </div>

                <div
                  class="pointer d-flex align-items-center"
                  @click="unfav(item, i, $event)"
                >
                  <i class="el-icon-view"></i>
                  <span class="text-gray-6 ml-10 small">{{ item.pv }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-20" v-if="params.pages > 1 && tab === 0">
          <el-pagination
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="params.page"
            :page-size="params.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="params.total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Header from "../layout/Header.vue";
export default {
  name: "Detail",
  components: {
    Header,
  },
  data() {
    return {
      loading: false,
      tab: 0,
      id: 0,
      detail: {},
      metaTitle: "",
      listLoading: false,
      lists: [], //列表
      searchTimer: null, //搜索计时器
      params: {
        //查询参数

        org_id: "",
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.id = this.$route.params.id;

        this.getDetail();
      },
      deep: true,
    },
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getDetail();
  },
  metaInfo() {
    return {
      title: this.metaTitle + "-机构-" + this.Settings.base.name,
    };
  },
  mounted() {},
  methods: {
    switchTab(tab) {
      this.tab = tab;
    },

    getDetail() {
      this.loading = true;
      this.$api.organization.Organization.detail({ id: this.id })
        .then((res) => {
          this.loading = false;
          this.detail = res.data;
          this.metaTitle = this.detail.name;
          this.getList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //取得视频列表
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.listLoading = true;
      this.params.org_id = this.id;
      this.$api.video.Video.lists(this.params)
        .then((res) => {
          this.listLoading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    search(keyword) {
      console.log("keyword:", keyword);
      this.params.keyword = keyword;
      this.getList();
    },
    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
    //点赞
    fav(item, i, e) {
      e.stopPropagation();
      this.$api.video.Video.fav({
        id: item.id,
      })
        .then((res) => {
          this.lists[i].fav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    },
    //点踩
    unfav(item, i, e) {
      e.stopPropagation();
      this.$api.video.Video.unfav({
        id: item.id,
      })
        .then((res) => {
          this.lists[i].unfav += res.data.add;
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    },
  },
};
</script>

